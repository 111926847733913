<template>
  <article class="media" v-bind:class="{ box: !isModal }">
    <div :id="`post_${post._id}`" :ref="`post_${post._id}`" :class="{ collapsed: shouldCollapse }"
      class="media-content">
      <div v-if="shouldCollapse" class="blurred-fg"></div>
      <div class="content">
        <div class="post-head">
          <div class="post-head-row">
            <div @click.prevent="pushToProfile" class="clickable mobile-spaced">
              <img class="image is-64h" :src="avatarImage" />
            </div>
            <div class="post-textual">
              <div class="post-meta">
                <div class="post-nick">
                  <div v-if="myProfile">
                    <small>
                      {{ $t(`access.${getPostAccess}`) }}
                      {{ $t('posts.post') }}
                    </small>
                    <small class="pl-5">
                      ({{ $t(post.status) }}
                      <i class="material-icons">
                        {{
                          formatStatusIcon(post.status)
                        }}
                      </i>)
                    </small>
                  </div>
                  <div v-else>
                    <span>{{ user.nickname }}</span>
                    <span v-if="post.locked">
                      <small class="pl-5">({{ $t(`access.${getPostAccess}`) }})</small>
                    </span>
                    <span v-else-if="post.access !== 'public' && post.accessUntil">
                      <small class="pl-5">
                        ({{ $t('posts.accessableUntil') }}:
                        {{ localeDate(post.accessUntil, $i18n.locale) }})
                      </small>
                    </span>
                    <span v-else>
                      <small class="pl-5">({{ $t(`access.${getPostAccess}`) }})</small>
                    </span>
                  </div>
                </div>
                <div class="post-date">
                  <div v-if="!isModal && !sticky">
                    <small>
                      <nuxt-link :to="
                        localePath({
                          name: 'id-post-slug-postId',
                          params: {
                            id: user.urlAlias,
                            postId: post._id,
                            slug: urlSlug(post.title),
                            title: `${user.nickname} - ${post.title}`
                          }
                        })
                      " data-qa="post-link" class="post-url gray-blueish">{{ formatDate(post.publishDateTime) }}
                      </nuxt-link>
                    </small>
                  </div>
                  <div v-else>
                    <small v-if="!sticky" class="gray-blueish">
                      {{
                        formatDate(post.publishDateTime)
                      }}
                    </small>
                  </div>
                </div>
              </div>
              <h4 v-if="!isModal" class="post-title">
                <nuxt-link :to="
                  localePath({
                    name: 'id-post-slug-postId',
                    params: {
                      id: user.urlAlias,
                      postId: post._id,
                      slug: urlSlug(post.title),
                      title: `${user.nickname} - ${post.title}`
                    }
                  })
                " data-qa="post-link" class="post-url">{{ post.title }}</nuxt-link>
              </h4>
              <h4 v-else class="post-title">{{ post.title }}</h4>
              <div v-if="post.tags" class="tags post-tags mb-15">
                <nuxt-link v-for="tag of post.tags" :key="tag._id" :to="localePath({
                  name: tagIds.includes(tag.id) ? 'id-posts' : 'id-posts-tag',
                  params: tagIds.includes(tag.id) ? { id: user.urlAlias } : { id: user.urlAlias, tag: tag.slug },
                })" class="tag is-light" :class="{ 'is-primary': tagIds.includes(tag.id) }">
                  {{ tag.name }}
                </nuxt-link>
              </div>
            </div>
          </div>
          <div :class="{ 'mb-15': !isAuthenticated }" class="post-text" v-html="post.post"></div>
        </div>
        <div class="post-content" v-if="!post.locked">
          <div v-if="post.livestream && post.livestream.url" class="post-media-section">
            <div v-if="!twitchChannel">
              <div v-if="allowedFrameSource(post.livestream.url)" style="position: relative; padding-bottom: 56.25%">
                <iframe class="stream-frame" width="100%" height="100%" frameborder="0" seamless
                  allow="autoplay; fullscreen" allowfullscreen="true" webkitAllowFullScreen="true"
                  :src="post.livestream.url"></iframe>
              </div>
              <a v-else :href="post.livestream.url" :title="livestreamDisplayName" class="file-item item"
                target="_blank">
                <i class="material-icons">tv</i>
                <span :class="{
                  truncated: post.livestream.url === livestreamDisplayName
                }">{{ livestreamDisplayName }}</span>
              </a>
            </div>
            <no-ssr v-else>
              <twitch-player :channel="twitchChannel" :refId="`twitch-video-${post._id}`"></twitch-player>
            </no-ssr>
          </div>
          <div v-if="post.externalLink && post.externalLink.url" class="post-media-section">
            <a :href="post.externalLink.url" :title="externalLinkDisplayName" class="file-item item" target="_blank">
              <i class="material-icons">exit_to_app</i>
              <span :class="{
                truncated: post.externalLink.url === externalLinkDisplayName
              }">{{ externalLinkDisplayName }}</span>
            </a>
          </div>
          <div v-if="hasMedia" class="post-media">
            <div v-if="hasPDFs" class="pdf-gallery post-media-section">
              <a :href="pdf.path" :title="pdf.meta.displayName" class="file-item item" v-for="pdf in pdfs"
                :key="pdf.id">
                <i class="material-icons">picture_as_pdf</i>
                <p class="item-meta">
                  <span v-if="pdf.meta.displayName" :class="{ truncated: pdf.path === pdf.meta.displayName }">{{
                    pdf.meta.displayName
                  }}</span>
                  <span v-else>{{ pdf.name }}</span>
                  <span v-if="pdf.meta.description">-</span>
                  {{ pdf.meta.description }}
                </p>
              </a>
            </div>
            <div v-if="hasZips" class="zip-gallery post-media-section">
              <a :href="zip.path" :title="zip.meta.displayName" class="file-item item" v-for="zip in zips"
                :key="zip.id">
                <i class="material-icons">get_app</i>
                <p class="item-meta">
                  <span v-if="zip.meta.displayName" :class="{ truncated: zip.path === zip.meta.displayName }">{{
                    zip.meta.displayName
                  }}</span>
                  <span v-else>{{ zip.name }}</span>
                  <span v-if="zip.meta.description">-</span>
                  {{ zip.meta.description }}
                </p>
              </a>
            </div>
            <div v-if="hasImages" class="image-gallery post-media-section">
              <gallery :images="images" />
            </div>
            <div v-if="audios.length > 0" class="audio-gallery post-media-section">
              <div class="audio-item item" v-for="(audio, idx) in audios" :key="audio.id">
                <p class="item-meta">
                  {{ audio.meta.displayName }}
                  <span v-if="audio.meta.displayName && audio.meta.description">-</span>
                  {{ audio.meta.description }}
                </p>

                 <audio-player
                  :audio="audio"
                  :downloadName="urlSlug(audio.meta.displayName || `${post.title} track ${idx + 1}`)" />
              </div>
            </div>
            <div v-if="videos.length > 0" class="video-gallery post-media-section">
              <div class="video-item item" v-for="video in videos" :key="video.id">
                <p class="item-meta">
                  {{ video.meta.displayName }}
                  <span v-if="video.meta.displayName && video.meta.description">-</span>
                  {{ video.meta.description }}
                </p>
                <videoPlayer :video="video" />
              </div>
            </div>
          </div>
          <div v-if="post.poll" class="mt-20">
            <poll :poll="post.poll" />
          </div>
        </div>
        <div v-else>
          <post-placeholder-flagged v-if="placeholderFeatureActive" :user="user" :post="post" :isModal="isModal" />
          <post-placeholder v-else :user="user" :post="post" :isModal="isModal" />
        </div>
      </div>
    </div>
    <div v-if="!isModal" class="post-actions mt-0">
      <button v-if="!post.locked && this.postExpandable" @click="toggleCollapsed"
        class="btn-expand button is-fullwidth">{{ collapsedButtonText }}</button>
      <socialShare class="social-share" :artist="user" :post="post" :fullPath="fullPath"></socialShare>
    </div>
  </article>
</template>

<script>
import gallery from '~/components/gallery'
import profilePost from '~/components/profile-post'
import postPlaceholder from '~/components/post-placeholder'
import postPlaceholderFlagged from '~/components/post-placeholder-flagged'
import socialShare from '~/components/social-share'
import videoPlayer from '~/components/video-player'
import audioPlayer from '~/components/audio-player'
import poll from '~/components/poll'
import twitchPlayer from '~/components/twitch'

import { mapState } from 'vuex'

export default {
  name: 'comments',
  props: ['user', 'post', 'index', 'community', 'isModal', 'sticky'],

  components: {
    profilePost,
    gallery,
    postPlaceholder,
    postPlaceholderFlagged,
    socialShare,
    videoPlayer,
    audioPlayer,
    poll,
    twitchPlayer
  },

  data() {
    return {
      images: [],
      videos: [],
      audios: [],
      pdfs: [],
      zips: [],
      pollOption: false,
      collapsed: true,
      isMounted: false
    }
  },

  computed: {
    // TODO: Let API tell frontend what's inside
    hasMedia() {
      return (this.hasImages || this.hasAudios || this.hasVideos || this.hasPDFs || this.hasZips)
    },
    hasImages() {
      return this.images.length
    },
    hasAudios() {
      return this.audios.length
    },
    hasVideos() {
      return this.videos.length
    },
    hasPDFs() {
      return this.pdfs.length
    },
    hasZips() {
      return this.zips.length
    },
    fullPath() {
      return `https://${this.host}/${this.user.urlAlias}/post/${this.urlSlug(this.post.title)}/${this.post._id}`
    },
    getPostAccess() {
      if (this.post.access === 'rewards') {
        return 'protected'
      }
      return this.post.access
    },
    avatarImage() {
      if (!this.user.profile) return ''
      if (typeof this.user.profile.imgs.avatar === 'string') {
        return this.user.profile.imgs.avatar
      }

      return `/services/media/image/${this.user._id}/${this.user.profile.imgs.avatar.name}/192/0`
    },
    myProfile() {
      if (this.isAuthenticated) {
        return this.$store.state.user.nickname === this.user.nickname
      }
      return false
    },

    postOffsetValue() {
      if (!this.isMounted) return 0
      return this.$el.offsetHeight
    },

    postExpandable() {
      if (!this.isMounted) return false
      let mediaCount = 0
      if (this.post) {
        mediaCount = (this.post.inlineMedia || []).length + (this.post.media || []).length
      }
      return this.postOffsetValue > 680 || mediaCount > 2
    },

    shouldCollapse() {
      return !this.post.locked && !this.isModal && this.collapsed && this.postExpandable
    },
    collapsedButtonText() {
      return this.collapsed ? this.$t('posts.expandButton.expand') : this.$t('posts.expandButton.collapse')
    },
    livestreamDisplayName() {
      if (!this.post.livestream || !this.post.livestream.url) return ''
      if (!this.post.livestream.displayName || this.post.livestream.displayName === '') {
        return this.post.livestream.url
      }
      return this.post.livestream.displayName
    },
    externalLinkDisplayName() {
      if (!this.post.externalLink || !this.post.externalLink.url) return ''
      if (!this.post.externalLink.displayName || this.post.externalLink.displayName === '') {
        return this.post.externalLink.url
      }
      return this.post.externalLink.displayName
    },
    twitchChannel() {
      if (!this.post || !this.post.livestream || !this.post.livestream.url) {
        return false
      }
      const match = this.post.livestream.url.match(/^https:\/\/www\.twitch\.tv\/([a-z0-9_]+)$/)
      if (!match || match.length < 2) return false
      return match[1]
    },
    tagIds() {
      if (!this.postFilter) return []
      return this.postFilter.tags.map((t) => t.id)
    },
    placeholderFeatureActive() {
      return ['nena', 'test', 'uscreen-account', 'hakuna-mipaka'].includes(this.user.urlAlias)
    },
    ...mapState(['isAuthenticated', 'host', 'postFilter'])
  },

  methods: {
    likePost(id) {
      console.log('Liked post with id', id)
    },
    formatAccess(access) {
      if (access === 'public') return 'Public'
      else if (access === 'authorized') return 'Paid'
      else if (access === 'rewards') return 'Reward'
      else if (access === 'fans') return 'Fan'
      else return 'Unknown'
    },
    formatStatusIcon(status) {
      if (status === 'draft') return 'drafts'
      else if (status === 'scheduled') return 'hourglass_empty'
      else if (status === 'published') return 'language'
    },
    toggleCollapsed() {
      if (!this.collapsed) {
        this.$root.$emit('scrollToId', `post_${this.post._id}`)
      }
      this.collapsed = !this.collapsed
    },
    pushToProfile() {
      const path = `/${this.user.urlAlias}`
      if (this.$route.path === path) {
        this.$scrollTo('#profile-navigation')
      }

      this.$router.push({
        name: `id___${this.$i18n.locale}`,
        params: {
          id: this.user.urlAlias
        }
      })
    },
    allowedFrameSource(url) {
      // only allow this one
      return url.startsWith('https://gnt.ironrobots.com/')
    },
    handleAudioContextMenu(event, args) {
      console.log('event', event)
      console.log('args', args)
    }
  },
  head() {
    return {
      title: this.isModal ? `${this.user.nickname} - ${this.post.title}` : this.user.nickname
    }
  },

  mounted() {
    if (this.post.media) {
      this.images = this.getImages(this.post.media)
      this.videos = this.getVideos(this.post.media)
      this.audios = this.getAudios(this.post.media)
      this.pdfs = this.getPDFs(this.post.media)
      this.zips = this.getZips(this.post.media)
    }
    this.isMounted = true
  }
}
</script>


<style lang="scss">
@import "assets/sass/_variables";

article {
  .post-head {
    .clickable {
      cursor: pointer;
    }
    img {
      float: left;
      margin-bottom: 0;
    }
    img.is-64h {
      height: 64px;
    }
    iframe {
      &.ql-video {
        width: 100%;
        min-height: 350px;
      }
    }
    .post-url {
      color: black;
      &.gray-blueish {
        color: $gt-gray-blueish;
      }
    }

    .post-text {
      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  .media-content {
    .blurred-fg {
      height: 300px;
      width: 100%;
      position: relative;
      z-index: 0;
      display: none;
      pointer-events: none;
      content: "";
    }

    &.collapsed {
      max-height: 300px;
      overflow-y: hidden;

      .blurred-fg {
        display: block;
        background-image: linear-gradient(
          to bottom,
          rgba(128, 128, 128, 0) 80%,
          rgba(255, 255, 255, 0.9) 95%,
          #fff 100%
        );
      }
    }

    .blurred-fg + .content {
      margin-top: -300px;
    }

    .stream-frame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .post-actions {
    flex-flow: column nowrap;

    .btn-expand {
      color: #fff;
      background-color: $gt-blue-dark;

      &:hover {
        background-color: $gt-blue-medium;
      }
    }
  }

  &.media.box {
    flex-flow: column nowrap;

    .media-content {
      width: 100%;
    }

    .post-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      width: 100%;
    }
  }

  .image-gallery {
    .preview-img-list {
      width: 100%;

      figure {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 548px) {
  article {
    .post-head {
      img {
        float: none;
        margin-bottom: 10px;
      }
    }
  }
}

.inline-image {
  clear: both !important;
  float: none !important;
  display: block !important;
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100%;
}

@media only screen and (max-width: $mobile) {
  .post-head-row {
    font-size: 0.8rem;
    display: flex;

    .post-textual {
      width: 100%;
    }
  }

  article.media {
    &.box {
      box-shadow: none;
    }
  }
}

.post-head {
  .clickable {
    &.mobile-spaced {
      margin-right: 8px;
    }
  }

  img {
    margin-right: 1em;
    float: left;
  }

  .post-meta {
    margin-bottom: 0.25em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .post-nick {
      margin: 0;
      padding: 0;
      font-size: 0.88rem;
      align-self: flex-end;

      i {
        font-size: 1em;
        line-height: 1.1em;
        vertical-align: text-bottom;
        display: inline-flex;
        font-size: 1em;
        color: $gt-blue-dark;
      }
    }

    .post-date {
      font-size: 0.88rem;
      text-align: right;
      align-self: flex-start;
    }
  }

  .post-title {
    margin-top: 0.5em;
  }
}

.post-content {
  overflow-x: hidden;
  padding-top: 20px;

  .truncated {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.post-media .post-media-section {
  display: flex;
  flex-flow: column nowrap;
  margin: 0px 0 0px 0px;

  .item,
  figure {
    margin: 12px 0;

    .item-meta {
      font-size: 0.9em;
    }
  }
}

.audio-item {
  display: flex;
  flex-flow: column nowrap;

  audio {
    width: 100%;
  }
}

.file-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: $gt-blue-light;
  color: $gt-blue-dark;
  padding: 10px;

  i {
    margin-right: 8px;
  }
}

.community {
  margin-top: 10px !important;
  margin-bottom: 0;

  .media-content {
    font-size: 0.9rem;
    margin-top: 0 !important;

    figure {
      margin-left: 0;
      margin-right: 10px;
    }

    .comments {
      margin-top: 20px;

      .comment-controls {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        &.unauthorized {
          position: relative !important;
          display: inline-block;
          font-size: 0.7rem !important;
        }
      }

      .vue-portal-target {
        margin-top: 10px;
      }
    }
  }

  .hidden {
    display: none !important;
  }

  .small {
    font-size: 0.8rem;
    padding-left: 30px;

    .material-icons {
      &.icon-comment {
        font-size: 1.5em;
      }
    }
  }

  // .material-icons {
  //   &.icon-heart {
  //     font-size: .8rem;
  //   }
  //   &.icon-comment {
  //     font-size: .6rem;
  //     margin-right: 3px;
  //   }
  // }
}
</style>
