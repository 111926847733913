var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"placeholder"},[_c('div',{staticClass:"placeholder-content-wrapper"},[_c('div',{staticClass:"placeholder-text"},[_c('div',[_c('section',[(!_vm.hasOffers && _vm.post.limitToFollowers)?_c('div',{staticClass:"placeholder-heading"},[_c('p',[_vm._v(_vm._s(_vm.$t('offerAccessToFollowers', { nickname: _vm.artist.nickname })))]),_vm._v(" "),(_vm.user.artist)?_c('followButton',{attrs:{"user":_vm.user,"fullWidth":false,"withIcon":false,"outlined":true,"minWidth":true}}):_vm._e()],1):(!_vm.hasOffers)?_c('p',{staticClass:"placeholder-heading"},[_vm._v(_vm._s(_vm.artist.nickname)+" "+_vm._s(_vm.$t('offerNoAccess')))]):_c('div',[_c('p',{staticClass:"placeholder-heading"},[_vm._v(_vm._s(_vm.artist.nickname)+" "+_vm._s(_vm.$t('offerAccess')))]),_vm._v(" "),(_vm.post.limitToFollowers)?_c('div',{staticClass:"mb-15"},[_c('p',[_vm._v(_vm._s(_vm.$t('offerAccessToFollowers', { nickname: _vm.artist.nickname })))]),_vm._v(" "),(_vm.user.artist)?_c('followButton',{attrs:{"user":_vm.user,"fullWidth":false,"withIcon":false,"outlined":true,"minWidth":true}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.cheapestReward)?_c('div',{staticClass:"content"},[_c('p',[_vm._v(_vm._s(_vm.$t('support'))+" "+_vm._s(_vm.artist.nickname)+" "+_vm._s(_vm.$t('minimumPledge'))+" "+_vm._s(_vm.centToEuro(_vm.cheapestReward.amount, true))+" "+_vm._s(_vm.$t('perMonth'))+" "+_vm._s(_vm.$t('byOpting')))]),_vm._v(" "),_c('button',{staticClass:"button is-primary same-min-width is-clickable is-outlined",on:{"click":function($event){_vm.$router.push(
                    _vm.localePath({
                      name: 'id-post-slug-postId',
                        params: {
                          id: _vm.artist.urlAlias,
                          postId: _vm.post._id,
                          slug: _vm.urlSlug(_vm.post.title),
                          title: `${_vm.artist.nickname} - ${_vm.post.title}`,
                          itemType: 'reward'
                        }
                    })
                  )}}},[_vm._v(_vm._s(_vm.$t('rewards.name')))])]):_vm._e(),_vm._v(" "),(_vm.cheapestReward && _vm.cheapestProduct)?_c('p',[_vm._v(_vm._s(_vm.$t('or')))]):_vm._e(),_vm._v(" "),(_vm.cheapestProduct)?_c('div',{staticClass:"content"},[_c('p',[(!_vm.cheapestReward)?_c('span',[_vm._v(_vm._s(_vm.$t('support'))+" "+_vm._s(_vm.artist.nickname))]):_vm._e(),_vm._v("\n                "+_vm._s(_vm.$t('minimumPurchase'))+" "+_vm._s(_vm.centToEuro(_vm.cheapestProduct.amount, true))+" "+_vm._s(_vm.$t('byOpting'))+"\n              ")]),_vm._v(" "),_c('button',{staticClass:"button is-primary same-min-width is-clickable is-outlined",on:{"click":function($event){_vm.$router.push(
                    _vm.localePath({
                      name: 'id-post-slug-postId',
                      params: {
                        id: _vm.artist.urlAlias,
                        postId: _vm.post._id,
                        slug: _vm.urlSlug(_vm.post.title),
                        title: `${_vm.artist.nickname} - ${_vm.post.title}`,
                        itemType: 'single-reward'
                      }
                    })
                  )}}},[_vm._v(_vm._s(_vm.$t('singleRewards.name')))])]):_vm._e()])])]),_vm._v(" "),(!_vm.postProducts.length && !_vm.postRewards.length && !_vm.post.limitToFollowers)?_c('div',{staticClass:"mt-10"},[_vm._v(_vm._s(_vm.$t('postWithoutActivePurchasable')))]):_vm._e()]),_vm._v(" "),(_vm.post.stats)?_c('div',{staticClass:"placeholder-icons"},[_c('ul',{staticClass:"icon-holder"},[(_vm.post.stats.images)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.images} ${_vm.$t('posts.media.images')}`}},[_c('i',{staticClass:"icon-photo active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.videos)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.videos} ${_vm.$t('posts.media.videos')}`}},[_c('i',{staticClass:"icon-video active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.audios)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.audios} ${_vm.$t('posts.media.audios')}`}},[_c('i',{staticClass:"icon-audio active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.pdfs)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.pdfs} ${_vm.$t('posts.media.pdfs')}`}},[_c('i',{staticClass:"icon-pdf active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.zips)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.zips} ${_vm.$t('posts.media.zips')}`}},[_c('i',{staticClass:"icon-zip active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.externalLink)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.$t('posts.media.extlink_included')}`}},[_c('i',{staticClass:"icon-link active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.livestream)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.$t('posts.media.livestream_included')}`}},[_c('i',{staticClass:"icon-live active"})])]):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }