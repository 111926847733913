<template>
  <article class="placeholder">
    <div class="placeholder-content-wrapper">
      <div class="placeholder-text">
        <div>
          <section>
            <div
              v-if="!hasOffers && post.limitToFollowers"
            >
              <p class="placeholder-heading">{{$t('placeholderFeature.intro')}}</p>
              <p>{{ $t('placeholderFeature.offerAccessToFollowers') }}</p>

              <followButton
                v-if="user.artist"
                :user="user"
                :fullWidth="false"
                :withIcon="false"
                :outlined="true"
                :minWidth="true"
              />
            </div>
            <p
              v-else-if="!hasOffers"
              class="placeholder-heading"
            >{{artist.nickname}} {{$t('offerNoAccess')}}</p>
            <div v-else-if="post.limitToFollowers && (cheapestReward || cheapestProduct)">
              <p class="placeholder-heading">{{$t('placeholderFeature.intro')}}</p>

              <p v-if="cheapestReward && cheapestProduct">{{ $t('placeholderFeature.option1', { amount: centToEuro(cheapestReward.amount, true) }) }}</p>
              <p v-else-if="cheapestReward">{{ $t('placeholderFeature.option2', { amount: centToEuro(cheapestReward.amount, true) }) }}</p>
              <p v-else>{{ $t('placeholderFeature.option3', { amount: centToEuro(cheapestProduct.amount, true) }) }}</p>

              <div class="is-flex is-justify-content-space-evenly is-fullwidth">
                <followButton
                  v-if="user.artist"
                  :user="user"
                  :fullWidth="false"
                  :withIcon="false"
                  :outlined="true"
                  :minWidth="true"
                />

                <button
                  v-if="cheapestReward"
                  class="button is-primary same-min-width is-clickable is-outlined"
                  @click="$router.push(
                      localePath({
                        name: 'id-post-slug-postId',
                          params: {
                            id: artist.urlAlias,
                            postId: post._id,
                            slug: urlSlug(post.title),
                            title: `${artist.nickname} - ${post.title}`,
                            itemType: 'reward'
                          }
                      })
                    )"
                >{{ $t('placeholderFeature.subscribe') }}</button>

                <button
                  v-if="cheapestProduct"
                  class="button is-primary same-min-width is-clickable is-outlined"
                  @click="$router.push(
                      localePath({
                        name: 'id-post-slug-postId',
                        params: {
                          id: artist.urlAlias,
                          postId: post._id,
                          slug: urlSlug(post.title),
                          title: `${artist.nickname} - ${post.title}`,
                          itemType: 'single-reward'
                        }
                      })
                    )"
                >{{ $t('placeholderFeature.purchase') }}</button>
              </div>
            </div>
            <div v-else>
              <p class="placeholder-heading">{{$t('placeholderFeature.intro')}}</p>
              <p v-if="cheapestReward && cheapestProduct">{{ $t('placeholderFeature.option4', { amount: centToEuro(cheapestReward.amount, true) }) }}</p>
              <p v-else-if="cheapestReward">{{ $t('placeholderFeature.option5', { amount: centToEuro(cheapestReward.amount, true) }) }}</p>
              <p v-else>{{ $t('placeholderFeature.option5', { amount: centToEuro(cheapestProduct.amount, true) }) }}</p>

              <div class="is-flex is-justify-content-space-evenly is-fullwidth">
                <button
                    v-if="cheapestReward"
                    class="button is-primary same-min-width is-clickable is-outlined"
                    @click="$router.push(
                      localePath({
                        name: 'id-post-slug-postId',
                          params: {
                            id: artist.urlAlias,
                            postId: post._id,
                            slug: urlSlug(post.title),
                            title: `${artist.nickname} - ${post.title}`,
                            itemType: 'reward'
                          }
                      })
                    )"
                  >{{ $t('placeholderFeature.subscribe') }}</button>

                <button
                  v-if="cheapestProduct"
                  class="button is-primary same-min-width is-clickable is-outlined"
                  @click="$router.push(
                    localePath({
                      name: 'id-post-slug-postId',
                      params: {
                        id: artist.urlAlias,
                        postId: post._id,
                        slug: urlSlug(post.title),
                        title: `${artist.nickname} - ${post.title}`,
                        itemType: 'single-reward'
                      }
                    })
                  )"
                >{{ $t('placeholderFeature.purchase') }}</button>
              </div>
            </div>
          </section>
        </div>
        <div
          v-if="!postProducts.length && !postRewards.length && !post.limitToFollowers"
          class="mt-10"
        >{{$t('postWithoutActivePurchasable')}}</div>
      </div>
      <div v-if="post.stats" class="placeholder-icons">
        <ul class="icon-holder">
          <li v-if="post.stats.images">
            <abbr :title="`${post.stats.images} ${$t('posts.media.images')}`">
              <i class="icon-photo active"></i>
            </abbr>
          </li>
          <li v-if="post.stats.videos">
            <abbr :title="`${post.stats.videos} ${$t('posts.media.videos')}`">
              <i class="icon-video active"></i>
            </abbr>
          </li>
          <li v-if="post.stats.audios">
            <abbr :title="`${post.stats.audios} ${$t('posts.media.audios')}`">
              <i class="icon-audio active"></i>
            </abbr>
          </li>
          <li v-if="post.stats.pdfs">
            <abbr :title="`${post.stats.pdfs} ${$t('posts.media.pdfs')}`">
              <i class="icon-pdf active"></i>
            </abbr>
          </li>
          <li v-if="post.stats.zips">
            <abbr :title="`${post.stats.zips} ${$t('posts.media.zips')}`">
              <i class="icon-zip active"></i>
            </abbr>
          </li>
          <li v-if="post.stats.externalLink">
            <abbr :title="`${$t('posts.media.extlink_included')}`">
              <i class="icon-link active"></i>
            </abbr>
          </li>
          <li v-if="post.stats.livestream">
            <abbr :title="`${$t('posts.media.livestream_included')}`">
              <i class="icon-live active"></i>
            </abbr>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>

<script>
import { mapState } from 'vuex'
import profilePost from '~/components/profile-post'
import followButton from '~/components/follow-button'

export default {
  name: 'comments',
  props: ['user', 'post', 'isModal'],

  components: {
    profilePost,
    followButton
  },

  computed: {
    postRewards() {
      return this.artist.rewards
        .filter(r => this.post.limitToReward.includes(r._id.toString()))
        .sort((a, b) => {
          if (a.amount > b.amount) {
            return 1
          } else if (a.amount < b.amount) {
            return -1
          }
          return 0
        })
    },
    availableProducts() {
      return this.artist.products.filter(p => {
        return p.stockRemaining === null || p.stockRemaining > 0
      })
    },
    postProducts() {
      return this.availableProducts
        .filter(p => this.post.limitToProduct.includes(p._id.toString()))
        .sort((a, b) => {
          if ((a.minAmount || a.amount) > (b.minAmount || b.amount)) {
            return 1
          } else if ((a.minAmount || a.amount) < (b.minAmount || b.amount)) {
            return -1
          }
          return 0
        })
    },

    hasOffers() {
      return this.postRewards.length || this.postProducts.length
    },

    cheapestProduct() {
      if (!this.postProducts.length) return null
      const cheapestProduct = this.postProducts[0]
      return {
        _id: cheapestProduct._id,
        amount: cheapestProduct.minAmount || cheapestProduct.amount
      }
    },

    cheapestReward() {
      if (!this.postRewards.length) return null
      const cheapestReward = this.postRewards[0]
      return cheapestReward
    },

    ...mapState(['artist', 'isAuthenticated'])
  },

  methods: {}
}
</script>

<style lang="scss">
@import 'assets/sass/_variables';
@import 'assets/sass/icons';

.placeholder {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  // cursor: pointer;

  .same-min-width {
    min-width: 100px;
    button {
      min-width: 100px;
    }
  }

  .placeholder-content-wrapper {
    .placeholder-text {
      text-align: center;

      padding: 30px;
      box-shadow: 0px 0px 12px 0px #cfcfcf;

      font-size: 0.9rem;
      color: #1e1c25;

      .placeholder-heading {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }

      .purchasable-list {
        padding: 0px;
        margin-left: 0px;
        display: block;
        list-style: none;
        order: 0;
        li {
          display: block;
        }
      }

      .link-dark-bg {
        &:hover {
          color: $light;
        }
      }
    }
    .placeholder-icons {
      ul li {
        margin: 0 10px;
        color: black;
        abbr {
          i {
            height: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $mobile) {
  .placeholder-text {
    margin: 1rem !important;
    padding: 1rem !important;
    font-size: 0.8rem !important;
    line-height: 1rem !important;
  }
}
@media screen and (min-width: $desktop) {
  .placeholder {
    width: 100%;
    overflow: hidden;
    overflow: visible;
  }
}
</style>
