<template lang="html">
  <section v-if="pollData && pollData.active">
    <transition name="poll" mode="out-in">
      <div class="panel" v-if="!pollData.hasVoted" key="options">
        <div class="panel-heading is-primary">
          <span>{{ pollData.question }}</span>
          <div v-if="available && pollData.multiple" class="is-size-6">
          ({{$t('pollMaxAnswers', { maxAnswers:  Math.min(pollData.options.length, pollData.maxAnswers || 1) })}})
          </div>
        </div>
        <div class="options" v-if="available">
          <div class="panel-block"
            v-for="option in pollData.options"
            v-if="option.title">
            <input v-model="voted"
              class="is-checkradio is-circle is-block is-danger"
              :type="pollData.multiple ? 'checkbox' : 'radio'"
              :id="option._id"
              :value="option._id"
              :disabled="pollData.multiple && !hasVotesRemaining && !isSelected(option._id)">
            <label
              :for="option._id">
              {{ option.title }}
            </label>
          </div>
        </div>
        <article class="placeholder" v-else>
          <div class="placeholder-content-wrapper">
            <div class="placeholder-text">
              <p>{{$t('please')}} <nuxt-link :to="localePath({ name: 'portal-login'})"
                v-if="!isAuthenticated">
                {{$t('login')}}
              </nuxt-link> {{$t('toVote')}}</p>
            </div>
          </div>
        </article>
        <div class="panel-block"
          v-if="isAuthenticated">
          <button :disabled="!voted.length"
            class="button is-primary is-fullwidth"
            @click="sendPollVote">
            {{$t('sendVote')}}
          </button>
        </div>
      </div>
      <div class="panel" v-else key="result">
        <div class="panel-heading is-primary">
          {{ pollData.question }}
        </div>
        <div class="panel-block"
          v-for="(option, index) in pollData.options"
          v-bind:key="index"
          v-if="option.title">
          <div class="column is-half">
            <label class="is">{{ option.title }}</label>
          </div>
          <div class="column is-one-third">
            <div class="bar" v-bind:style="{ width: getRelativePercentage(pollData.options, option) + '%' }"></div>
          </div>
          <div class="column has-text-right">
            {{ getRelativePercentage(pollData.options, option) }}%
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<style lang="scss">
  .poll-enter-active, .poll-leave-active,
  .result-enter-active, .result-leave-active {
    transition: opacity .5s;
  }
  .poll-enter, .poll-leave-to,
  .result-enter, .result-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .bar {
    height: 1rem;
    background-color: grey;
  }
  article.placeholder {
    border-top: none;
    margin-top: 10px;
  }
  .panel-block {
    align-items: center;
    color: #121212;
    display: flex;
    justify-content: flex-start;
    padding: 0.5em 0.75em;

    label {
      &::before, &:after {
        margin-top: 3px;
      }
    }
  }
</style>

<script>
import { mapState } from 'vuex'

export default {
  props: ['poll'],
  data: () => ({
    voted: [],
    pollData: {}
  }),
  computed: {
    available() {
      return !this.poll.notAvailable
    },

    hasVotesRemaining() {
      return ((this.pollData.maxAnswers || 1) - this.voted.length) > 0
    },
    ...mapState(['isAuthenticated'])
  },
  methods: {
    isSelected(id) {
      return this.voted.includes(id)
    },
    async sendPollVote() {
      const pollVoted = await this.apiPost(`/api/private/polls/${this.pollData._id}/vote`, {
        optionIds: typeof this.voted === 'string' ? [this.voted] : this.voted
      })
      if (pollVoted) {
        this.pollData = pollVoted.poll
        this.$store.dispatch('refreshPollData', this.pollData)
      }
    }
  },
  created() {
    this.pollData = this.poll
  }
}
</script>
