<template>
  <section class="section mt-30">
    <div class="container">
      <div class="columns">
        <div v-show="!isMobileView" class="column side-container"
          :class="isMobileView ? 'is-hidden' : 'is-one-quarter'">
          <sidenav></sidenav>
        </div>
        <div class="column" :class="isMobileView ? 'is-full' : 'is-half'">
          <article class="is-profilebox">
            <div class="mobile-profilebox-container">
              <sidenav id="mobile-sidenav" :class="{ 'is-hidden': !isMobileView }"></sidenav>
              <div :class="{ 'is-hidden': isMobileView }" class="content profileDescription">
                <no-ssr>
                  <div v-if="artist.profile.description" class="has-description">
                    <div v-html="artist.profile.description"></div>
                  </div>
                </no-ssr>
              </div>
            </div>
          </article>
          <div class="filter-controls">
            <div class="mobile-filter-posts">
              <portal-target name="mobileFilterPosts" slim />
            </div>
          </div>
          <div v-if="artist.profile.configuration.displayTwitchChannel && twitchChannel" class="media box">
            <no-ssr>
              <twitch-player :channel="twitchChannel" :refId="`twitch-video-profile`"></twitch-player>
            </no-ssr>
          </div>
          <profile-post :user="artist" :post="post" :index="index" :key="post._id" :sticky="true"
            v-for="(post, index) in sortedStickyPosts" />

          <profile-post :user="artist" data-qa="post" :post="post" :index="index" :key="post._id"
            v-for="(post, index) in segmentedPosts" />

          <nuxt-link v-show="isMobileView" class="button height-3em is-primary is-fullwidth" :to="localePath({ name: 'id-posts', params: { id: artist.urlAlias } })
            ">{{ $t('posts.gotoPosts') }}</nuxt-link>
          <button id="load-more-button" @click="loadMore" v-show="!isMobileView && postIndex < postCount"
            class="button height-3em is-primary is-outlined is-fullwidth">
            <span id="load-more-text">{{ $t('loadMore') }}</span>
            <div id="spinner-container">
              <hollow-dots-spinner v-show="postsLoading" id="hollow-dots" :animation-duration="1000" :dot-size="10"
                :dots-num="3" color="#fb3e52" />
            </div>
          </button>
        </div>
        <div class="column is-one-quarter">
          <profile-purchasables :sideview="true" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import sidenav from '~/components/sidenav'
import profilePost from '~/components/profile-post'
import profilePurchasables from '~/components/profile-purchasables'
import ctaMobile from '~/components/ctaMobile'
import twitchPlayer from '~/components/twitch'
import { HollowDotsSpinner } from 'epic-spinners/dist/lib/epic-spinners.min.js'

export default {
  props: ['params'],

  components: {
    profilePost,
    profilePurchasables,
    sidenav,
    ctaMobile,
    twitchPlayer,
    HollowDotsSpinner
  },

  head() {
    return {
      title: this.artist.nickname
    }
  },

  data() {
    return {
      postIndex: 0,
      postInterval: 5,
      currentSegment: 1,
      postsLoading: false,
      toasts: []
    }
  },

  computed: {
    ...mapState(['artist', 'user', 'isAuthenticated', 'postFilter', 'profilePostsLoaded', 'toastNotifications']),
    postCount() {
      return this.filteredPosts.length
    },
    filteredPosts() {
      if (!this.artist || !this.artist.posts) return []
      return this.filterPosts(this.postFilter, this.artist.posts)
    },
    filteredStickyPosts() {
      if (!this.artist || !this.artist.stickyPosts) return []
      return this.filterPosts(this.postFilter, this.artist.stickyPosts)
    },
    segmentedPosts() {
      this.postIndex = this.postInterval * this.currentSegment
      if (this.postIndex > this.postCount) {
        this.postIndex = this.postCount
      }
      return this.filteredPosts.slice(0, this.postIndex)
    },
    sortedStickyPosts() {
      if (
        !this.filteredStickyPosts ||
        !this.filteredStickyPosts.length) {
        return []
      }
      if (!this.filteredStickyPosts[0].stickyOrder) return this.filteredStickyPosts
      return this.filteredStickyPosts.sort((a, b) => {
        if (a.stickyOrder === b.stickyOrder) return 0
        return a.stickyOrder > b.stickyOrder ? 1 : -1
      })
    },
    twitchChannel() {
      if (!this.artist) return false
      const twitch = this.artist.socialAccounts.twitch
      if (!twitch || !twitch.account) return false
      const match = twitch.account.match(/^https:\/\/www\.twitch\.tv\/([a-z0-9_]+)$/)
      if (!match || match.length < 2) return false
      return match[1]
    }
  },

  methods: {
    async loadMore() {
      if (!this.profilePostsLoaded) {
        this.postsLoading = true
        await this.loadAllPosts({
          urlAlias: this.artist.urlAlias
        })
        this.postsLoading = false
      }
      this.currentSegment += 1
    },
    scrollToId(id) {
      const elem = document.getElementById(id)
      if (elem) {
        this.$scrollTo(elem)
      }
    },
    ...mapActions(['loadAllPosts', 'clearToastNotification'])
  },

  mounted() {
    this.$root.$on('scrollToId', this.scrollToId)
  },

  beforeDestroy() {
    this.$root.$off('scrollToId', this.scrollToId)
  }

}
</script>

<style lang="scss">
@import "assets/sass/_variables";
@import "epic-spinners/dist/lib/epic-spinners.min.css";

.is-mobile-profileDescription {
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding-top: 0px !important;
  padding-left: 15px !important;
}

.is-mobile-profilebox {
  .mobile-profilebox-container {
    display: flex;

    #profilebox {
      padding: 0.5rem;
      line-height: 1.6rem;
      margin-bottom: 0.5rem;

      .avatar-placeholder {
        height: 125px;
      }
    }
  }
}

.filter-controls {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .mobile-filter-posts {
    padding-left: 20px;
    width: 100%;

    .multi-select-filter {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
    }
  }
}

.height-3em {
  height: 3em;
}

#load-more-button {
  margin-left: 0px !important;

  &:hover {
    #hollow-dots {
      .dot {
        border-color: #ffffff !important;
      }
    }
  }

  #load-more-text {
    margin-left: 55px;
  }

  #spinner-container {
    max-width: 35px;
    width: 35px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: $mobile-portrait) {
  .profileDescription {
    font-size: $font-size-smaller;
  }
}
</style>
